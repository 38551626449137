import React from "react";

const SectionDividerDark = (): JSX.Element => {
    return (
        <div className={'bg-zinc-800 flex flex-row grid grid-cols-7 py-[50px] w-[100%] items-center justify-center px-[25%]'}>
            <div className={'h-[1px] bg-zinc-200 col-span-3 mx-1'}></div>
            <div className={'mx-1 col-span-1 flex justify-center'}>
                <div className={'h-[20px] w-[20px] bg-zinc-200 rounded-full'}></div>
            </div>
            <div className={'h-[1px] bg-zinc-200 col-span-3 mx-1'}></div>
        </div>

    )
};

export default SectionDividerDark;