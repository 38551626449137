const scrollToItem = (id: string) => {
    document.getElementById(id)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
    })
};

export const sliderTime = 10000;

export default scrollToItem;