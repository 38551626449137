import { Routes, Route, Link } from "react-router-dom";

import { Home } from "../../views/Home";
import Restaurants from "../../views/Restaurants";
import EnquiriesAndBooking from "../../views/EnquiriesAndBooking";
import Gallery from "../../views/Gallery";
import BarAndLounge from "../../views/BarAndLounge";
import Spa from "../../views/Spa";
import { Rooms } from "../../views/Rooms";
import tripadvisor from "../../images/tripadvisor.jpg"

const AppHeader = () => {
    return (
        <div className={'w-[100%]'}>
            <div className="w-[100%] flex md:flex-col lg:flex-row">
            <img src={tripadvisor} className={"w-[120px] h-[120px] hidden lg:block"} alt=""/>
            <div className={'w-[100%] flex flex-col md:flex-col lg:flex-row  mt-14 md:justify-center items-center'} id={'navMenu'}>
            <img src={tripadvisor} className={"w-[120px] h-[120px] lg:hidden"} alt=""/>
                <Link to={'/'} className={'text-white font-medium text-2xl hover:text-[#be2332] mx-4'}>
                    Home
                </Link>
                <Link to={'/rooms'} className={'text-white font-medium text-2xl hover:text-[#be2332] mx-4'}>
                    Our Rooms
                </Link>
                <Link to={'/restaurant'} className={'text-white font-medium text-2xl hover:text-[#be2332] mx-4'}>
                    Restaurant & Bar
                </Link>
                <Link to={'/spa'} className={'text-white font-medium text-2xl hover:text-[#be2332] mx-4'}>
                    Spa & Pool
                </Link>
                <Link to={'/gallery'} className={'text-white font-medium text-2xl hover:text-[#be2332] mx-4'}>
                    Gallery
                </Link>
                <Link to={'/booking'} className={'text-white font-medium text-2xl hover:text-[#be2332] mx-4    '}>
                    Contact Us
                </Link>
            </div>
            </div>
            
            <Routes>
                <Route path={'/'} element={<Home/>} />
                <Route path={'/rooms'} element={<Rooms/>} />
                <Route path={'/booking'} element={<EnquiriesAndBooking/>} />
                <Route path={'/gallery'} element={<Gallery/>} />
                <Route path={'/lounge'} element={<BarAndLounge/>} />
                <Route path={'/spa'} element={<Spa/>} />
                <Route path={'/restaurant'} element={<Restaurants/>} />
            </Routes>

        </div>
    )
}

export default AppHeader