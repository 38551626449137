import React from 'react';
import { BrowserRouter } from "react-router-dom";

import AppHeader from "../components/header/AppHeader";
import Background from "../images/bg_alt.jpg";
import Footer from "../components/footer/Footer";

function App() {
  return (
      <BrowserRouter>
          <div className={'bg-fixed bg-center bg-cover h-screen flex flex-row md:h-auto'}
               style={{backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat"}}>
              <div className={'flex flex-col h-screen w-screen'} style={{background: 'rgba(0,0,0,0.25)' ,backdropFilter: 'blur(5px)'}}>
                  <AppHeader/>
                  <Footer/>
              </div>
          </div>
      </BrowserRouter>
  );
}

export default App;
