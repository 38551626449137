const EMAIL_JS = require('@emailjs/browser')
const mailer = async (mailData: ReservationData | QueriesData): Promise<any> => {
    EMAIL_JS.init(process.env.REACT_APP_EMAIL_JS_PUBLIC)
    let template: string = process.env.REACT_APP_QUERIES as string
    return EMAIL_JS.send(process.env.REACT_APP_SERVICEID, template, mailData)
}

type ReservationData = {
    name: string;
    email: string;
    guests: number;
    arrival: string;
    departure: string;
    comments?: string;
}

type QueriesData = {
    name: string;
    email: string;
    comments?: string;
}

export default mailer;