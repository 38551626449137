const SectionHeader = ({ title, description }: any) => {
    return (
        <>
            <h2 className={'text-white text-4xl font-normal justify-center flex'}>{title}</h2>
            <p className={'text-gray-500 text-2xl font-light justify-center flex mt-2 italic'}>
                {description}
            </p>
        </>
    )
};

export default SectionHeader;