export const appImages = {
    logo_original: require('../images/logo.jpg'),
    logo: require('../images/logo-transparent.png'),
    small_exclusive: require('../images/app/_MG_3601.jpg'),
    comfortable_friendly: require('../images/app/_MG_3625.jpg'),
    path_night: require('../images/app/_MG_3765.jpg'),
    pool_1: require('../images/app/_MG_4000.jpg'),
    pool_2: require('../images/app/_MG_3980.jpg'),
    pool_3: require('../images/app/pool_three.jpeg'),
    restaurant_1: require('../images/app/_MG_3759.jpg'),
    restaurant_2: require('../images/app/_MG_3975.jpg'),
    restaurant_3: require('../images/app/_MG_3967.jpg'),
    restaurant_4: require('../images/app/_MG_3702.jpg'),
    restaurant_5: require('../images/app/IMG_9472.jpg'),
    restaurant_6: require('../images/app/IMG_9473.jpg'),
    restaurant_7: require('../images/app/IMG_9474.jpg'),
    rooms_1: require('../images/app/_MG_3702.jpg'),
    rooms_2: require('../images/app/_MG_3779.jpg'),
    rooms_3: require('../images/app/_MG_3890.jpg'),
    rooms_4: require('../images/app/_MG_3902.jpg'),
    rooms_5: require('../images/app/_MG_3905.jpg'),
    rooms_6: require('../images/app/_MG_3908.jpg'),
    rooms_7: require('../images/app/_MG_3919.jpg'),
    rooms_8: require('../images/app/_MG_3924.jpg'),
    rooms_9: require('../images/app/_MG_3944.jpg'),
    rooms_10: require('../images/app/_MG_3948.jpg'),
    rooms_11: require('../images/app/_MG_3952.jpg'),
    rooms_12: require('../images/app/IMG_8759.jpg'),
    bar_1: require('../images/app/_MG_3670.jpg'),
    bar_2: require('../images/app/_MG_3671.jpg'),
    bar_3: require('../images/app/_MG_3838.jpg'),
    lounge_1: require('../images/app/_MG_3822.jpg'),
    lounge_2: require('../images/app/_MG_3967.jpg'),
    lounge_3: require('../images/app/_MG_3655.jpg'),
    lounge_4: require('../images/app/IMG_8759.jpg'),
    eggs: require('../images/app/IMG_EGGS.jpeg'),
}