import {Link} from "react-router-dom";
import { Carousel } from "flowbite-react";
import {appImages} from "../utils/appImages";

const BarAndLounge = (): JSX.Element => {
    return (
        <>
            <div className={'bg-transparent'}>

                <div className={'w-full flex flex-row mt-[70px] justify-center'}>
                    <h1 className={'text text-3xl font-extrabold text-white'}>Lounge</h1>
                </div>

                <div className={'mt-2 mb-[70px] flex flex-row justify-center items-center'}>
                    <Link to={'/'} className={'text text-xl font-bold text-white mx-2 hover:cursor-pointer hover:text-[#be2332]'}>Home</Link>
                    <span className={'material-icons text-white font-bold mx-2'}>chevron_right</span>
                    <h2 className={'text text-xl font-bold text-white mx-2'}>Lounge</h2>
                </div>

                <div className={'bg-white flex flex-row justify-center py-[50px]'}>

                    <div className={'container'}>

                        <div className={'flex flex-row justify-center mt-7'}>
                            <h1 className={'text text-4xl text-[#be2332] font-bold items-center'}>Serenity and Quality</h1>
                        </div>

                        <div className={'flex flex-col justify-between items-center mt-5 md:px-[10%]'}>
                            <div className={'flex flex-col px-10 md:px-0'}>
                                <div className={'flex flex-row justify-center'}>
                                    <h1 className={'text text-black text-2xl font-medium text-center'}>Wind down at our exclusive lounge areas</h1>
                                </div>

                                <div className={'flex flex-row mt-4 justify-center'}>
                                    <h1 className={'text text-zinc-700 text-lg font-light text-center'}>
                                        The bar is well stocked with local as well as imported beverages.
                                    </h1>
                                </div>

                                <div className={'flex flex-row mt-1'}>
                                    <h1 className={'text text-zinc-700 text-lg font-light text-center'}>
                                        Our guests are treated to the famous flamingo hill cocktails whilst relaxing in our comfortable and open Lounge with a view of the plains. Recline, get pampered by our staff and enjoy an African sundowner!
                                        Socialise with other guests around a cosy fire in the evenings or simply get comfortable with a book from our Library.
                                    </h1>
                                </div>
                            </div>
                            <div className={'flex flex-col  w-[100%] mt-14 px-10 md:px-0'}>

                                <div className="h-[30vh] sm:h-[25vh] xl:h-[45vh] 2xl:h-[60vh] mt-10">
                                    <Carousel slide={true}>
                                        
                                        <img
                                            className={'object-cover h-[100%]'}
                                            src={appImages.lounge_1}
                                            alt="..."
                                        />

                                        <img
                                            className={'object-cover h-[100%]'}
                                            src={appImages.lounge_3}
                                            alt="..."
                                        />

                                        <img
                                            className={'object-cover h-[100%]'}
                                            src={appImages.lounge_2}
                                            alt="..."
                                        />

                                        <img 
                                            className={'object-cover h-[100%]'}
                                            src={'https://flamingohillcamp.co.ke/wp-content/uploads/2019/07/Flamingo-Hill-Camp-9-1024x544.jpg'}
                                            alt="..." 
                                        />

                                        <img
                                            className={'object-cover h-[100%]'}
                                            src={appImages.lounge_4}
                                            alt="..."
                                        />

                                    </Carousel>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default BarAndLounge;