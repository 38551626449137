import {Link} from "react-router-dom";
import React, {Dispatch, FormEvent, SetStateAction, useState} from "react";
import SectionDividerDark from "../components/SectionDividerDark";
import scrollToItem from "../utils/uiActions";
import mailer from "../utils/mailer";

const EnquiriesAndBooking = (): JSX.Element => {

    // queries
    const [queryName, setQueryName] = useState("")
    const [queryEmail, setQueryEmail] = useState("")
    const [queryComments, setQueryComments] = useState("")
    const [queryLoading, setQueryLoading] = useState(false)

    const submitQuery = (e: FormEvent) => {
        e.preventDefault()
        setQueryLoading(true)
        mailer({
            name: queryName,
            email: queryEmail,
            comments: queryComments})
            .then((_) => {
                setQueryLoading(false)
                alert('Your request has been received. We will get back to you shortly')
            })
            .catch((_) => {
                setQueryLoading(false)
                alert('There was an unexpected error. Please try again later!')
            })
    }

    // reservations
    const [resName, setResName] = useState("")
    const [resEmail, setResEmail] = useState("")
    const [resGuests, setResGuests]: [number|undefined , Dispatch<SetStateAction<number|undefined>>] = useState()
    const [resArrival, setResArrival] = useState("")
    const [resDeparture, setResDeparture] = useState("")
    const [resComments, setResComments] = useState("")
    const [resLoading, setResLoading] = useState(false)

    const submitReservation = (e: FormEvent) => {
        e.preventDefault();
        setResLoading(true)
        mailer({
            name: resName,
            email: resEmail,
            comments: `
            Reservation Request 
         
            Name: ${resName}
            Email: ${resEmail}
            Guests: ${resGuests}
            Arrival Date: ${resArrival}
            Departure Date: ${resDeparture}
            
            Extra Comments:
            ${resComments} 
            `})
            .then((_r) => {
                setResLoading(false)
                alert('Your request has been received. We will get back to you shortly')
            })
            .catch((_e) => {
                setResLoading(false)
                alert('There was an unexpected error. Please try again later!')
            })
    }


    return (
        <>
            <div className={'bg-transparent'}>

                <div className={'w-full flex flex-row mt-[70px] justify-center'}>
                     <h1 className={'text text-3xl font-extrabold text-white'}>Booking & Enquiries</h1>
                 </div>

                <div className={'mt-2 mb-[70px] flex flex-row justify-center items-center'}>
                    <Link to={'/'} className={'text text-xl font-bold text-white mx-2 hover:cursor-pointer hover:text-[#be2332]'}>Home</Link>
                    <span className={'material-icons text-white font-bold mx-2'}>chevron_right</span>
                    <h2 className={'text text-xl font-bold text-white mx-2'}>Booking & Enquiries</h2>
                </div>

                <div id={'reservation'} className={'bg-zinc-800 flex flex-row justify-center py-[50px]'}>

                    <div className={'container'}>

                        <div className={'flex flex-row justify-center mt-7'}>
                            <h1 className={'text text-4xl text-white font-bold'}>Make A Reservation</h1>
                        </div>

                        <div className={'flex md:flex-row flex-col justify-between items-center mt-14 md:px-[10%] px-4'}>

                            <form className={'flex flex-col md:w-[45%] w-[100%]'} onSubmit={submitReservation}>
                               <input className={'p-5 border border-black rounded-[35px] my-[10px]'}
                                      placeholder={"Name *"} type={'text'} required={true}
                                      value={resName} onChange={(e) => setResName(e.target.value)}/>

                                <input className={'p-5 border border-black rounded-[35px] my-[10px]'}
                                       placeholder={"Email *"} type={'email'} required={true}
                                       value={resEmail} onChange={(e) => setResEmail(e.target.value)}/>

                                <input className={'p-5 border border-black rounded-[35px] my-[10px]'}
                                       placeholder={"No of Guests *"} type={'number'} required={true}
                                       value={resGuests} onChange={(e) => setResGuests(parseInt(e.target.value))}/>

                                <input className={'p-5 border border-black rounded-[35px] my-[10px]'}
                                       placeholder={"Arrival Date *"} type={'text'}
                                       onFocus={(e) => e.target.type = 'date'}
                                       onBlur={(e) => e.target.type = 'text'} required={true}
                                       value={resArrival} onChange={(e) => setResArrival(e.target.value)}/>

                                <input className={'p-5 border border-black rounded-[35px] my-[10px]'}
                                       placeholder={"Departure Date *"} type={'text'}
                                       onFocus={(e) => e.target.type = 'date'}
                                       onBlur={(e) => e.target.type = 'text'} required={true}
                                       value={resDeparture} onChange={(e) => setResDeparture(e.target.value)}/>

                                <textarea className={'p-5 border border-black rounded-[35px] mt-[10px]'}
                                          placeholder={'Additional Comments'} rows={5}
                                          value={resComments} onChange={(e) => setResComments(e.target.value)}
                                ></textarea>

                                <button className={'bg-[#be2332] my-[20px] rounded-[35px] p-5 w-[50%] text-white drop-shadow-xl'}>
                                    SEND
                                </button>


                                { resLoading ? <div className={'flex flex-row justify-center mt-[30px] mb-[30px]'}>
                                    <span className={'animate-ping h-[75px] w-[75px] rounded-full bg-[#be2332]'}>
                                    </span>
                                </div> : <></>}


                            </form>


                            <div className={'flex flex-col md:w-[45%] w-[100%] px-4 md:px-0'}>

                                {/* <img className={'w-[96px] h-[96px]'} src="https://img.icons8.com/wired/64/null/event-accepted.png" alt={'accepted'}/> */}

                                <div className={'flex flex-row mt-14'}>
                                    <h1 className={'text text-white text-2xl font-medium'}>Reserve your spot today.</h1>
                                </div>

                                <div className={'flex flex-row mt-4'}>
                                    <h1 className={'text font-light text-white'}>
                                        Take a chance today with our small and exclusive camp, comprising 25 tents. The tents are furnished with the understated luxury required by the modern traveler.
                                        <br/><br/>Decorated in calming safari tones, natural woods, wrought iron and crisp cottons each tent has been meticulously planned to maximise comfort.
                                    </h1>
                                </div>

                                <div className={'flex flex-row justify-center my-6 items-center'}>
                                    <div className={'bg-slate-300 h-[2px] w-[150px] mx-4 hidden md:inline-flex'}></div>
                                    <h1 className={'text font-bold text-[#be2332] text-4xl'}>OR</h1>
                                    <div className={'bg-slate-300 h-[2px] w-[150px] mx-4 hidden md:inline-flex'}></div>
                                </div>

                                <div className={'flex flex-row justify-center'}>
                                    <button className={'bg-rose-800 p-5 rounded-[35px] text-white font-bold'} onClick={() => scrollToItem('queries')}>
                                        MAKE ENQUIRY
                                    </button>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <SectionDividerDark/>

                <div id={'queries'} className={'bg-zinc-800 flex flex-row justify-center py-[50px]'}>

                    <div className={'container'}>

                        <div className={'flex flex-row justify-center mt-7'}>
                            <h1 className={'text text-4xl text-white font-bold'}>Ask Your Queries</h1>
                        </div>

                        <div className={'flex md:flex-row flex-col mt-14 justify-between items-center md:px-[10%] px-4'}>

                            <form className={'flex flex-col md:w-[45%] w-[100%]'} onSubmit={submitQuery}>
                                <input  className={'p-5 border border-black rounded-[35px] my-[10px]'}
                                        placeholder={"Name *"} type={'text'} required={true}
                                        value={queryName} onChange={(e) => setQueryName(e.target.value)}/>

                                <input className={'p-5 border border-black rounded-[35px] my-[10px]'}
                                       placeholder={"Email *"} type={'email'} required={true}
                                       value={queryEmail} onChange={(e) => setQueryEmail(e.target.value)}/>

                                <textarea className={'p-5 border border-black rounded-[35px] mt-[10px]'}
                                          placeholder={'Additional Comments'} rows={5}
                                          value={queryComments} onChange={(e) => setQueryComments(e.target.value)}
                                ></textarea>

                                <div className={'flex flex-row my-[20px] justify-end'}>
                                    <button className={'bg-[#be2332] my-[20px] rounded-[35px] p-5 w-[50%] text-white drop-shadow-xl align-right'}>
                                        SEND
                                    </button>
                                </div>

                                { queryLoading ? <div className={'flex flex-row justify-center mt-[30px] mb-[30px]'}>
                                    <span className={'animate-ping h-[75px] w-[75px] rounded-full bg-[#be2332]'}>
                                    </span>
                                </div> : <></>}

                            </form>

                            <div className={'flex flex-col md:w-[45%] w-[100%]'}>

                                {/* <img className={'w-[96px] h-[96px]'} src="https://img.icons8.com/wired/64/null/ask-question.png" alt={'question'}/> */}

                                <div className={'flex flex-row mt-14'}>
                                    <h1 className={'text text-white text-2xl font-medium'}>General Queries</h1>
                                </div>

                                <div className={'flex flex-row mt-4'}>
                                    <h1 className={'text font-light text-white'}>
                                        Do you have any questions for us?<br/>
                                        Shoot us a message and we will get back to you.
                                    </h1>
                                </div>

                                <div className={'flex flex-row justify-center my-6 items-center'}>
                                    <div className={'bg-slate-300 h-[2px] w-[150px] mx-4 hidden md:inline-flex'}></div>
                                    <h1 className={'text font-bold text-[#be2332] text-4xl hidden md:inline-flex'}>OR</h1>
                                    <div className={'bg-slate-300 h-[2px] w-[150px] mx-4 hidden md:inline-flex'}></div>
                                </div>

                                <div className={'flex flex-row justify-center'}>
                                    <button className={'bg-rose-800 p-5 rounded-[35px] text-white font-bold'} onClick={() => scrollToItem('reservation')}>
                                        MAKE A RESERVATION
                                    </button>
                                </div>

                            </div>

                        </div>



                    </div>

                </div>

            </div>
        </>
    )
}

export default EnquiriesAndBooking